import {
    OTHER_USER_PROFILE_FAIL,
    OTHER_USER_PROFILE_REQUEST,
    OTHER_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAIL,
    UPDATE_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_SUCCESS,
    OTHER_USER_LOCATION_ADDRESS_FAIL,
    OTHER_USER_LOCATION_ADDRESS_REQUEST,
    OTHER_USER_LOCATION_ADDRESS_SUCCESS,
    USER_LOCATION_ADDRESS_FAIL,
    USER_LOCATION_ADDRESS_REQUEST,
    USER_LOCATION_ADDRESS_SUCCESS,
    USER_PROFILE_FAIL,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_REQUEST_FAIL,
    USER_REQUEST_REQUEST,
    USER_REQUEST_SUCCESS,
    USER_SIGNOUT_FAIL,
    USER_SIGNOUT_REQUEST,
    USER_SIGNOUT_SUCCESS,
    USER_VALIDATE_FAIL,
    USER_VALIDATE_REQUEST,
    USER_VALIDATE_SUCCESS,
    USER_MEETINGS_SUCCESS,
    USER_MEETINGS_FAIL,
    USER_MEETINGS_REQUEST,
} from '../constants/userConstants';

import { auth, db, func } from '../../firebase';
import { httpsCallable } from 'firebase/functions';

import { updateProfile } from 'firebase/auth';
import OneSignal from 'react-onesignal';

import {
    ref,
    get,
    push,
    set,
    serverTimestamp,
    update,
    onValue,
    off,
} from 'firebase/database';
import { getUserType, getUserTypeNumber } from '../../functions/getUser';
import { formatEmail } from '../../functions/formatEmail';
import {
    getLocationPath,
    getUserPath,
    getUserPathFromInt,
} from '../../functions/getPaths';
import isNotificationsEnabled from '../../onesignal/isNotificationsEnabled';

export const signout = (uid, ut) => async (dispatch) => {
    dispatch({ type: USER_SIGNOUT_REQUEST });
    try {
        console.log('USER_SIGNOUT_REQUEST');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('shippingAddress');
        localStorage.removeItem('shippingPersonalDetails');
        localStorage.removeItem('shippingMethod');
        localStorage.removeItem('paymentMethod');
        console.log('USER_SIGNOUT_REQUEST: removed local storage');
        const isPushSupported = await isNotificationsEnabled();
        if (isPushSupported) {
            const notId = await OneSignal.getUserId();
            const userPath = getUserPath(uid, ut);

            await set(
                ref(db, `${userPath}/Notifications/Tokens/${notId}`),
                null
            );
            console.log('USER_SIGNOUT_REQUEST: deleted Tokens');
            await OneSignal.setSubscription(false);
        }

        dispatch({ type: USER_SIGNOUT_SUCCESS });
        console.log('USER_SIGNOUT_SUCCESS');
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        dispatch({ type: USER_SIGNOUT_FAIL, payload: errorMessage });
    }
};

export const getUserProfile = (uid, userType) => async (dispatch) => {
    dispatch({
        type: USER_PROFILE_REQUEST,
    });
    try {
        const profileRef = ref(db, getUserPath(uid, userType));
        onValue(profileRef, (snapshot) => {
            if (snapshot.exists()) {
                if (
                    snapshot.hasChild('contact') &&
                    snapshot.hasChild('public')
                ) {
                    const userProfile = snapshot.val();
                    off(profileRef);
                    const userFlat = {
                        ...userProfile.contact,
                        ...userProfile.public,
                        _id: uid,
                    };

                    dispatch({ type: USER_PROFILE_SUCCESS, payload: userFlat });
                }
            }
        });
    } catch (error) {
        dispatch({ type: USER_PROFILE_FAIL, payload: error.message });
    }
};

export const getOtherUserProfile = (uid, userTypeInt) => async (dispatch) => {
    dispatch({
        type: OTHER_USER_PROFILE_REQUEST,
    });
    try {
        const profileRef = ref(
            db,
            getUserPathFromInt(uid, userTypeInt) + '/public'
        );
        console.log(profileRef);
        const snapshot = await get(profileRef);
        const userProfile = snapshot.val();
        userProfile._id = uid;
        userProfile.uid = uid;

        dispatch({ type: OTHER_USER_PROFILE_SUCCESS, payload: userProfile });
    } catch (error) {
        dispatch({ type: OTHER_USER_PROFILE_FAIL, payload: error.message });
    }
};

export const getUserLocationAddress = (uid, userType) => async (dispatch) => {
    dispatch({
        type: USER_LOCATION_ADDRESS_REQUEST,
    });
    try {
        const addressRef = ref(db, getLocationPath(uid, userType));

        onValue(addressRef, (snapshot) => {
            if (snapshot.exists()) {
                if (snapshot.hasChild('country')) {
                    const userAddress = snapshot.val();
                    userAddress._id = uid;
                    off(addressRef);
                    dispatch({
                        type: USER_LOCATION_ADDRESS_SUCCESS,
                        payload: userAddress,
                    });
                }
            } else {
                dispatch({
                    type: USER_LOCATION_ADDRESS_FAIL,
                    payload:
                        'No address found please update this in your profile.',
                });
            }
        });
    } catch (error) {
        dispatch({
            type: USER_LOCATION_ADDRESS_FAIL,
            payload: error.message,
        });
    }
};

export const getOtherUserLocationAddress =
    (uid, userType = getUserType(1)) =>
    async (dispatch) => {
        dispatch({
            type: OTHER_USER_LOCATION_ADDRESS_REQUEST,
        });
        try {
            const userPath = getLocationPath(uid, userType);
            const addressRef = ref(db, userPath);

            const snapshot = await get(addressRef);
            const userAddress = snapshot.val();
            userAddress._id = uid;

            dispatch({
                type: OTHER_USER_LOCATION_ADDRESS_SUCCESS,
                payload: userAddress,
            });
        } catch (error) {
            dispatch({
                type: OTHER_USER_LOCATION_ADDRESS_FAIL,
                payload: error.message,
            });
        }
    };

export const createUserSignupRequest =
    (userRequest, isAdmin = false) =>
    async (dispatch) => {
        dispatch({ type: USER_REQUEST_REQUEST });

        try {
            if (isAdmin) {
                const createNewUser = httpsCallable(func, 'createNewUser');
                const queryResult = await createNewUser({
                    user: userRequest,
                });

                dispatch({
                    type: USER_REQUEST_SUCCESS,
                    payload: queryResult,
                });
            } else {
                const setRef = ref(db, '/Authorised/');
                const resquestKey = push(setRef).key;

                await set(ref(db, 'Authorised/' + resquestKey), {
                    ...userRequest,
                    email: formatEmail(userRequest.email),
                    postedAt: serverTimestamp(),
                });
                const responseRequest = {
                    ...userRequest,
                    email: formatEmail(userRequest.email),
                    _id: resquestKey,
                };
                dispatch({
                    type: USER_REQUEST_SUCCESS,
                    payload: responseRequest,
                });
            }
        } catch (error) {
            dispatch({
                type: USER_REQUEST_FAIL,
                payload: error.message,
            });
        }
    };

export const validateUser = (email, code) => async (dispatch) => {
    dispatch({
        type: USER_VALIDATE_REQUEST,
    });
    try {
        const addressRef = ref(db, '/Authorised/' + code);

        const snapshot = await get(addressRef);
        const authUser = snapshot.val();

        const formattedEmail = formatEmail(email);

        if (authUser.email === formattedEmail) {
            const userInfo = {
                _id: code,
                ...authUser,
            };
            dispatch({
                type: USER_VALIDATE_SUCCESS,
                payload: userInfo,
            });
        } else {
            dispatch({
                type: USER_VALIDATE_FAIL,
                payload: 'Email addressess do not match!',
            });
        }
    } catch (error) {
        dispatch({
            type: USER_VALIDATE_FAIL,
            payload: error.message,
        });
    }
};

export const updateUserProfile =
    (uid, userType, profileData, addressData) => async (dispatch) => {
        dispatch({
            type: UPDATE_USER_PROFILE_REQUEST,
        });
        try {
            var updates = {};

            const rootRef = ref(db, '/');
            const formattedEmail = formatEmail(profileData.email);
            var publicProfile = { ...profileData, email: formattedEmail };

            const contact = {
                phone: profileData.phone,
                privatePhone: profileData.privatePhone,
                email: formattedEmail,
                privateEmail: profileData.privateEmail,
            };

            if (profileData.privateEmail) {
                delete publicProfile.email;
            }

            if (profileData.privatePhone) {
                delete publicProfile.phone;
            }

            if (addressData.businessName) {
                publicProfile.businessName = addressData.businessName;
            }

            if (addressData.businessEmail) {
                publicProfile.businessEmail = addressData.businessEmail;
            } else {
                publicProfile.businessEmail = null;
            }

            if (addressData.businessPhone) {
                publicProfile.businessPhone = addressData.businessPhone;
            } else {
                publicProfile.businessPhone = null;
            }

            if (addressData.city) {
                publicProfile.city = addressData.city;
            }

            if (addressData.businessUrl) {
                publicProfile.businessUrl = addressData.businessUrl;
            }

            publicProfile.city = addressData.city;
            publicProfile.country = addressData.country;

            updates[getLocationPath(uid, userType)] = addressData;

            updates[getUserPath(uid, userType) + '/contact'] = contact;
            updates[getUserPath(uid, userType) + '/public'] = publicProfile;

            await updateProfile(auth.currentUser, {
                displayName: profileData.name
                    ? profileData.name
                    : `${profileData.firstName} ${profileData.lastName}`,
            });

            await update(rootRef, updates);

            dispatch({
                type: UPDATE_USER_PROFILE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_USER_PROFILE_FAIL,
                payload: error.message,
            });
        }
    };

export const updateProfileEmail =
    (uid, userType, iniitalProfile, newEmail) => async (dispatch) => {
        dispatch({
            type: UPDATE_USER_PROFILE_REQUEST,
        });
        try {
            var updates = {};
            const rootRef = ref(db, '/');

            updates[getUserPath(uid, userType) + '/contact/email'] = newEmail;

            if (!iniitalProfile.privateEmail) {
                updates[getUserPath(uid, userType) + '/public/email'] =
                    newEmail;
            }

            await update(rootRef, updates);

            dispatch({
                type: UPDATE_USER_PROFILE_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_USER_PROFILE_FAIL,
                payload: error.message,
            });
        }
    };

export const getPublicUserProfile = (uid, userType) => async (dispatch) => {
    dispatch({
        type: USER_PROFILE_REQUEST,
    });
    try {
        const profileRef = ref(db, getUserPath(uid, userType) + '/public');
        const snapshot = await get(profileRef);
        const userProfile = snapshot.val();

        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: {
                ...userProfile,
                userType: userProfile.userType
                    ? userProfile.userType
                    : getUserTypeNumber(userType),
                _id: uid,
            },
        });
    } catch (error) {
        dispatch({ type: USER_PROFILE_FAIL, payload: error.message });
    }
};

export const createUserMeetings =
    (userRequest, meetingId) => async (dispatch) => {
        dispatch({ type: USER_MEETINGS_REQUEST });

        try {
            var responseRequest = { ...userRequest };
            const setRef = ref(db, 'Meetings/' + meetingId + '/');

            const resquestKey = await push(setRef).key;

            const baseRef = ref(db, '/');

            var updates = {};

            if (userRequest.pay) {
                const sumTotal = userRequest.quantity * 100;
                const amount = Number(sumTotal.toFixed(2));

                const paymentRef = ref(db, '/Payments/Requests');
                const newPaymentKey = push(paymentRef).key;
                updates[`/Payments/Requests/${newPaymentKey}`] = {
                    amount: amount,
                    item_name: 'Braai catering',
                    meetingId: meetingId,
                    registrationId: resquestKey,
                    createdAt: serverTimestamp(),
                };

                responseRequest = {
                    ...responseRequest,
                    paymentId: newPaymentKey,
                };
            }

            updates['Meetings/' + meetingId + '/' + resquestKey] = {
                ...responseRequest,
                postedAt: serverTimestamp(),
            };

            await update(baseRef, updates);

            responseRequest = {
                ...responseRequest,
                _id: resquestKey,
            };
            dispatch({ type: USER_MEETINGS_SUCCESS, payload: responseRequest });
        } catch (error) {
            dispatch({
                type: USER_MEETINGS_FAIL,
                payload: error.message,
            });
        }
    };
