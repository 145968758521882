export async function extractArrayFromFirebase(vals) {
    const idArray = Object.keys(vals);
    var objectArray = Object.values(vals);
    var formattedData = await objectArray.map(function (el, i) {
        var o = Object.assign({}, el);
        o._id = idArray[i];
        return o;
    });
    return formattedData;
}

export function getErrorMessage(error) {
    return error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
}

export async function mergeObjectArraysbyValue(array1, array2, value) {
    return [...array1, ...array2].reduce((acc, curr) => {
        const existingObject = acc.find((item) => item[value] === curr[value]);
        if (existingObject) {
            Object.assign(existingObject, curr);
        } else {
            acc.push(curr);
        }
        return acc;
    }, []);
}

export function firebaseKeyValueArray(values) {
    return Object.entries(values).map(([key, value]) => ({
        key,
        value,
    }));
}

export function sortArrayByChild(array, childKey, order = 'decending') {
    var formattedData = array ? array : [];
    if (formattedData && formattedData.length !== 0) {
        formattedData.sort((a, b) => {
            if (order === ' decending') {
                return a[childKey] < b[childKey]
                    ? 1
                    : b[childKey] < a[childKey]
                    ? -1
                    : 0;
            } else {
                return a[childKey] > b[childKey]
                    ? 1
                    : b[childKey] > a[childKey]
                    ? -1
                    : 0;
            }
        });
    }
    return formattedData;
}
